import React from "react";
import "./fonts.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./component/Sidebar/Sidebar";
import Dashboard from "./pages/Dashboard/Dashboard";
import Creators from "./pages/Creators/Creators";
import Transactions from "./pages/Transactions/Transactions";
import Invitemanagers from "./pages/Invitemanagers/Invitemanagers";
import Roles from "./pages/Roles/Roles";
import RevenueSplits from "./pages/RevenueSplits/RevenueSplits";
import Payouts from "./pages/Payouts/Payouts";
import Profile from "./pages/Profile/Profile";
import Settings from "./pages/Settings/Settings";
import Header from "./component/Header/Header";
import Creatorprofile from "./pages/Creatorprofile/Creatorprofile";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Members from "./pages/Members/Members";
import PrivateRoute from "./utility/PrivateRoute";
import Notification from "./pages/Notification/Notifications";


function App() {


  

  return (
    <Router>
      <div className="app">
      <Sidebar />
       <Header />
        <div className="content">
          <Routes>
            <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />

            <Route path="/creators" element={<PrivateRoute><Creators /></PrivateRoute>} />
            <Route path="/transactions" element={<PrivateRoute><Transactions /></PrivateRoute>} />
            <Route path="/invitemanagers" element={<PrivateRoute><Invitemanagers /></PrivateRoute>} />
            <Route path="/roles" element={<PrivateRoute><Roles /></PrivateRoute>} />
            <Route path="/revenuesplits" element={<PrivateRoute><RevenueSplits /></PrivateRoute>} />
            <Route path="/payouts" element={<PrivateRoute><Payouts /></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/members" element={<PrivateRoute><Members /></PrivateRoute>} />
            <Route path="/notification" element={<PrivateRoute><Notification /></PrivateRoute>} />
            <Route
              path="/creatorprofile/:username"
              element={<PrivateRoute><Creatorprofile /></PrivateRoute>}
            />
            <Route path="/forgot-password" element={<PrivateRoute><ForgotPassword /></PrivateRoute>} />
            <Route
              path="/reset-password/:code/:email"
              element={<ResetPassword />}
            />
          </Routes>

          
 
        </div>
      </div>
    </Router>
  );
}

export default App;

