import React, { useState, useEffect } from 'react';
import { api } from "../../utility/api";
import './Settings.css';

const Settings = () => {
  const [openSections, setOpenSections] = useState(
    () => JSON.parse(localStorage.getItem('openSections')) || []
  );
  useEffect(() => {
    localStorage.setItem('openSections', JSON.stringify(openSections));
  }, [openSections]);
  
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [errors, setErrors] = useState({});

  const toggleSection = (section) => {
    setOpenSections((prevSections) =>
      prevSections.includes(section)
        ? prevSections.filter((s) => s !== section)
        : [...prevSections, section]
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswords({
      ...passwords,
      [name]: value,
    });
  };

  // Updated password
  const validatePasswords = () => {
    const newErrors = {};

    if (!passwords.oldPassword) newErrors.oldPassword = 'Old Password is required';
    if (!passwords.newPassword) newErrors.newPassword = 'New Password is required';
    if (passwords.newPassword.length < 8) newErrors.newPassword = 'The password should be at least 8 characters';
    if (passwords.newPassword !== passwords.confirmNewPassword)
      newErrors.confirmNewPassword = 'Password didn’t match';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validatePasswords()) {
      try {
        const response = await api.post('agency-change-password', {
          old_password: passwords.oldPassword,
          new_password: passwords.newPassword,
          confirm_password: passwords.confirmNewPassword,
        });

        if (response.data.success) {
          alert('Password changed successfully.');
          setPasswords({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          });
          setErrors({});
        } else {
          alert('Password change failed.');
        }
      } catch (error) {
        console.error('Error changing password:', error);
        if (error.response && error.response.data) {
          console.log("Response Data:", error.response.data); // Log response data
          alert(`Error: ${error.response.data.message || 'Something went wrong.'}`);
        }
      }
    }
  };


  // Notifaction

  // const [settings, setSettings] = useState({
  //   newsletter: false,
  //   emailAlerts: false,
  //   ratingsReviews: false,
  //   priceAlerts: false,
  //   pushNotifications: false
  // });
  
  // useEffect(() => {
  //   const fetchSettings = async () => {
  //     try {
  //       const response = await api.get('notification-data');
  //       if (response.data.success) {
  //         const { data } = response.data;
  //         setSettings({
  //           newsletter: !!data.news_letter,
  //           emailAlerts: !!data.email_alerts,
  //           ratingsReviews: !!data.rating_reviews,
  //           priceAlerts: !!data.price_alert,
  //           pushNotifications: !!data.push_notification,
  //         });
  //       }
  //     } catch (error) {
  //       console.error("Error fetching notification settings:", error);
  //     }
  //   };

  //   fetchSettings();
  // }, []);

 
  // const handleToggle = (field) => {
  //   setSettings((prevSettings) => ({
  //     ...prevSettings,
  //     [field]: !prevSettings[field],
  //   }));
  // };

  
  // const handleSave = async () => {
  //   try {
  //     const response = await api.post('notification-settings', {
  //       news_letter: settings.newsletter ? 1 : 0,
  //       email_alerts: settings.emailAlerts ? 1 : 0,
  //       rating_reviews: settings.ratingsReviews ? 1 : 0,
  //       price_alert: settings.priceAlerts ? 1 : 0,
  //       push_notification: settings.pushNotifications ? 1 : 0,
  //     });
  
  //     if (response.data.success) {
  //       alert('Notification settings updated successfully!');
  //     } else {
  //       alert('Failed to update settings. Please try again.');
  //     }
  //   } catch (error) {
  //     console.error('Error updating notification settings:', error);
  //     alert('Error updating notification settings.');
  //   }
  // };
  

  // Payment
  const [bankDetails, setBankDetails] = useState({
    bankName: '',
    accountNumber: '',
    accountHolder: '',
    routingNumber: '',
    bankLocation: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const fetchBankDetails = async () => {
    try {
      const response = await api.get('/api/agency-bank-details'); // Ensure correct endpoint
      if (response.data.success) {
        const { bank_name, account_number, account_holder, code, bank_location } = response.data.data;
        setBankDetails({
          bankName: bank_name || '',
          accountNumber: account_number || '',
          accountHolder: account_holder || '',
          routingNumber: code || '',
          bankLocation: bank_location || '',
        });
        setErrorMessage('');
      } else {
        setBankDetails({
          bankName: '',
          accountNumber: '',
          accountHolder: '',
          routingNumber: '',
          bankLocation: '',
        });
        setErrorMessage('No bank details found for this agency ID.');
      }
    } catch (error) {
      console.error('Error fetching bank details:', error);
      setErrorMessage('Error fetching bank details.');
    }
  };

  useEffect(() => {
    if (openSections.includes('payment')) {
      fetchBankDetails();
    }
  }, [openSections]);


  return (
    <div className="settings-container">
      {/* Change Password Section */}
      <div className="setting-item">
        <div
          className={`setting-header ${openSections.includes('password') ? 'active-header' : ''}`}
          onClick={() => toggleSection('password')}
        >
          <img
            src={`${process.env.PUBLIC_URL}/icons/ChangePassword.svg`}
            alt="Change Password"
            className="icon"
          />
          <span>Change Password</span>
          <img
            src={`${process.env.PUBLIC_URL}/icons/Arrow.svg`}
            alt="Arrow"
            className={`arrow-icon ${openSections.includes('password') ? 'rotate' : ''}`}
          />
        </div>
        {openSections.includes('password') && (
          <div className="setting-content">
            <form className="change-password-form" onSubmit={handleSubmit}>
              <div className="input-group">
                <input
                  type="password"
                  placeholder="Old Password"
                  className="password-input"
                  name="oldPassword"
                  style={{ marginBottom: '15px' }}
                  value={passwords.oldPassword}
                  onChange={handleInputChange}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/icons/Eye.svg`}
                  alt="Show/Hide Password"
                  className="eye-icon"
                />
                <a href="/forgot-password" className="forgot-password">Forgot Password</a>
                {errors.oldPassword && <p className="error-text">{errors.oldPassword}</p>}
              </div>

              <div className="input-group">
                <input
                  type="password"
                  placeholder="New Password"
                  className="password-input"
                  name="newPassword"
                  value={passwords.newPassword}
                  onChange={handleInputChange}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/icons/Eye.svg`}
                  alt="Show/Hide Password"
                  className="eye-icon"
                />
                {errors.newPassword && <p className="error-text">{errors.newPassword}</p>}
              </div>

              <div className="input-group">
                <input
                  type="password"
                  placeholder="Confirm New Password"
                  className="password-input"
                  name="confirmNewPassword"
                  value={passwords.confirmNewPassword}
                  onChange={handleInputChange}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/icons/Eye.svg`}
                  alt="Show/Hide Password"
                  className="eye-icon"
                />
                {errors.confirmNewPassword && <p className="error-text">{errors.confirmNewPassword}</p>}
              </div>

              <button type="submit" className="update-password-btn">
                UPDATE PASSWORD
              </button>
            </form>
          </div>
        )}
      </div>

      {/* Notification Section */}
      
    {/* <div className="setting-item">
      <div
        className={`setting-header ${openSections.includes('notification') ? 'active-header' : ''}`}
        onClick={() => toggleSection('notification')}
      >
        <img  src={`${process.env.PUBLIC_URL}/icons/NotifactionSetting.svg`} alt="Notification Settings" className="icon" />
       
        <span>Notification Settings</span>
        <img
         src={`${process.env.PUBLIC_URL}/icons/Arrow.svg`}
          alt="Arrow"
          className={`arrow-icon ${openSections.includes('notification') ? 'rotate' : ''}`}
        />
      </div>
      {openSections.includes('notification') && (
        <div className="setting-contents">
          
          <div className="toggle_box">
            <div className="left">
              <h3>Newsletter</h3>
              <h4>You’ll get notification if there are any promotional offers</h4>
            </div>
            <div className="right">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={settings.newsletter}
                  onChange={() => handleToggle('newsletter')}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

          <div className="toggle_box">
            <div className="left">
              <h3>Email Alerts</h3>
              <h4>You’ll get notification after every payment transaction. Manage all other notifications here.</h4>
            </div>
            <div className="right">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={settings.emailAlerts}
                  onChange={() => handleToggle('emailAlerts')}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

          <div className="toggle_box">
            <div className="left">
              <h3>Ratings & Reviews</h3>
              <h4>You’ll get notification for every review and rating. Manage all other notifications here.</h4>
            </div>
            <div className="right">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={settings.ratingsReviews}
                  onChange={() => handleToggle('ratingsReviews')}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

          <div className="toggle_box">
            <div className="left">
              <h3>Price Alerts</h3>
              <h4>You’ll get notification after every payment transaction. Manage all other notifications here.</h4>
            </div>
            <div className="right">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={settings.priceAlerts}
                  onChange={() => handleToggle('priceAlerts')}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

          <div className="toggle_box">
            <div className="left">
              <h3>Push Notifications</h3>
              <h4>Get push notifications to find out what’s going on when you’re not on sneakpeach.</h4>
            </div>
            <div className="right">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={settings.pushNotifications}
                  onChange={() => handleToggle('pushNotifications')}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

        
          <div className="button button2">
            <button type="submit" className="update_button" onClick={handleSave}>
              SAVE
            </button>
          </div>
        </div>
      )}
    </div> */}

      {/* Payment Section */}
      <div className="setting-item">
        <div
          className={`setting-header ${openSections.includes('payment') ? 'active-header' : ''}`}
          onClick={() => toggleSection('payment')}
        >
          <img
            src={`${process.env.PUBLIC_URL}/icons/Payment.svg`}
            alt="Payment"
            className="icon"
          />
          <span>Payment</span>
          <img
            src={`${process.env.PUBLIC_URL}/icons/Arrow.svg`}
            alt="Arrow"
            className={`arrow-icon ${openSections.includes('payment') ? 'rotate' : ''}`}
          />
        </div>
        {openSections.includes('payment') && (
          <>
            {errorMessage && (
              <div className="error-message" style={{
                textAlign: "center",
                marginTop: "23px",
                marginBottom: "1px",
                color: "red",
              }}>
                <p>{errorMessage}</p>
              </div>
            )}
            <div className="setting-content payment-form">
              {/* Display error message if it exists */}


              <div className="form-group">
                <label>Banking Name</label>
                <input
                  type="text"
                  placeholder="Banking Name"
                  className="form-input"
                  name="bankName"
                  value={bankDetails.bankName}
                  readOnly // Make the field non-editable
                />
              </div>
              <div className="form-group">
                <label>Account Number</label>
                <input
                  type="text"
                  placeholder="02150154015645"
                  className="form-input"
                  name="accountNumber"
                  value={bankDetails.accountNumber}
                  readOnly // Make the field non-editable
                />
              </div>
              <div className="form-group">
                <label>Banking Name</label>
                <input
                  type="text"
                  placeholder="Banking Name"
                  className="form-input"
                  name="bankName"
                  value={bankDetails.bankName}
                  readOnly // Make the field non-editable
                />
              </div>
              <div className="form-group">
                <label>Account Type</label>
                <input
                  type="text"
                  placeholder="Current"
                  className="form-input"
                  name="accountHolder"
                  value={bankDetails.accountHolder}
                  readOnly // Make the field non-editable
                />
              </div>
              <div className="form-group">
                <label>ABA/Routing Number</label>
                <input
                  type="text"
                  placeholder="Add your bank's 9-digit ABA Code"
                  className="form-input"
                  name="routingNumber"
                  value={bankDetails.routingNumber}
                  readOnly // Make the field non-editable
                />
              </div>
              <div className="form-group">
                <label>Country of residence</label>
                <input
                  type="text"
                  placeholder="Residence"
                  className="form-input"
                  name="bankLocation"
                  value={bankDetails.bankLocation}
                  readOnly // Make the field non-editable
                />
              </div>
            </div>
          </>
        )}

      </div>

    </div>
  );
};

export default Settings;
