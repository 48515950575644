import React, { useState } from 'react';
import { api } from "../../utility/api";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Clear any previous errors

    try {
      const response = await api.post('agency-forgot-password', { email });
      if (response.data.success) {
        console.log('Password reset code sent successfully.');
        setIsModalOpen(true);
      } else {
        setErrorMessage('Failed to send reset link. Please check your email.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
      console.error('Error sending email:', error);
    }
  };

  const closeModal = () => {

    setIsModalOpen(false);
  };

  return (
    <div style={styles.container}>

      <div style={styles.textContainer}>
        <h2 style={styles.heading}>Trouble logging in?</h2>
        <p style={styles.paragraph}>
          Enter the email address you signed up with. We will send you an email with a link to get back into your account.
        </p>
      </div>

      {/* Form Card */}
      <div style={styles.card}>
        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label htmlFor="email" style={styles.label}>Email Id</label>
            <input
              type="email"
              id="email"
              placeholder="Email Id"
              style={styles.input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          {/* Display error message if there is any */}
          {errorMessage && <p style={styles.error}>{errorMessage}</p>}

          <button type="submit" style={styles.button}>
            CONTINUE
          </button>
        </form>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <button style={styles.closeButton} onClick={closeModal}>
              <img src={`${process.env.PUBLIC_URL}/icons/CancelOrange.svg`} alt="Close" style={styles.close} />
            </button>
            <img src={`${process.env.PUBLIC_URL}/icons/Mail.svg`} alt="Email Icon" style={styles.icon} />
            <h3 style={styles.modalHeading}>Check your email</h3>
            <p style={styles.modalParagraph}>
              Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f8f8',
    fontFamily: 'Proxima Nova sans-serif',
  },
  textContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  heading: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '10px',
  },
  paragraph: {
    fontSize: '1rem',
    color: '#666',
    marginBottom: '30px',
    lineHeight: '1.4',
  },
  card: {
    backgroundColor: '#fff',
    padding: '40px',
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '450px',
    width: '100%',
  },
  formGroup: {
    marginBottom: '20px',
    textAlign: 'left',
  },
  label: {
    fontSize: '0.9rem',
    color: '#333',
    marginBottom: '5px',
    display: 'block',
  },
  input: {
    width: '100%',
    padding: '12px 16px',
    borderRadius: '8px',
    border: '1px solid #e0e0e0',
    fontSize: '1rem',
    backgroundColor: '#f9f9f9',
    outline: 'none',
    boxSizing: 'border-box',
  },
  button: {
    width: '100%',
    padding: '12px',
    background: 'linear-gradient(90deg, #FFA626 12.26%, #FF8E0E 89.58%)',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    textTransform: 'uppercase',
    marginTop: '10px',
  },
  /* Modal Styles */
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: '#fff',
    padding: '40px',
    borderRadius: '12px',
    textAlign: 'center',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    width: '400px',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
  },
  icon: {
    width: '60px',
    height: '60px',
    marginBottom: '20px',
  },
  close: {
    width: '32px',
    height: '32px',
    marginBottom: '20px',
  },
  modalHeading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  modalParagraph: {
    fontSize: '1rem',
    color: '#666',
    lineHeight: '1.5',
  },
  error: {
    color: 'red',
    marginBottom: '20px',
  },
};

export default ForgotPassword;
