import React, { useState, useEffect, useCallback ,useRef  } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Transactions/Transactions.css";
import { api } from "../../utility/api";

const Transactions = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionsData, setTransactionsData] = useState([]);
  const [openItemIndex, setOpenItemIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); 
  const [nextPageUrl, setNextPageUrl] = useState(null); 
  const [prevPageUrl, setPrevPageUrl] = useState(null); 

  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component unmounts or modal closes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleItem = (index) => {
    setOpenItemIndex(openItemIndex === index ? null : index);
  };

  const handleFilterChange = (filter) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter((item) => item !== filter));
    } else {
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  // Update API call to include pagination
  const fetchTransactions = useCallback(async (page = 1) => {
    try {
      let url = `all-transactions?page=${page}&`;

      if (startDate) {
        url += `start_date=${startDate.toISOString().split("T")[0]}&`;
      }
      if (endDate) {
        url += `end_date=${endDate.toISOString().split("T")[0]}&`;
      }

      if (searchTerm) {
        url += `search=${searchTerm}&`;
      }

      if (selectedFilters.length > 0) {
        const filterQuery = selectedFilters
          .map((filter) => `filter_type[]=${filter}`)
          .join("&");
        url += `${filterQuery}&`;
      }

      const response = await api.get(url);
      const data = response.data;
      if (data.transactions) {
        setTransactionsData(data.transactions);
        setTotalPages(data.pagination.last_page); 
        setNextPageUrl(data.pagination.next_page_url); 
        setPrevPageUrl(data.pagination.prev_page_url); 
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  }, [startDate, endDate, searchTerm, selectedFilters]);

  useEffect(() => {
    fetchTransactions(currentPage); 
  }, [fetchTransactions, currentPage]);

  // Handle page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page); 
    }
  };

  return (
    <div className="transactions-page">
      {/* Header */}
      <div className="transactions-header d-flex justify-content-between align-items-center p-4">
        <div className="d-flex align-items-center">
          <img
            src={`${process.env.PUBLIC_URL}/icons/TransactionFrame.svg`}
            alt="Transaction Icon"
            className="transaction-icon me-3"
          />
          <h2 className="heading-main">Transactions</h2>
        </div>

        {/* Date Range Filters */}
        <div className="d-flex align-items-center">
          <div className="date-input-wrapper me-3">
            <div className="icon-with-text">
              <img
                src={`${process.env.PUBLIC_URL}/icons/Date.svg`}
                alt="Calendar Icon"
                className="icon"
              />
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="From"
                className="date-picker-input"
              />
            </div>
          </div>

          <div className="date-input-wrapper me-3">
            <div className="icon-with-text">
              <img
                src={`${process.env.PUBLIC_URL}/icons/Date.svg`}
                alt="Calendar Icon"
                className="icon"
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="To"
                className="date-picker-input"
              />
            </div>
          </div>

          {/* Get Details button */}
          <button
            className="btn custom-button text-white"
            onClick={fetchTransactions}
          >
            GET DETAILS
          </button>
        </div>
      </div>

      {/* Search and Filter */}
      <div className="search-filter-container d-flex align-items-center">
        <div className="search-bars me-3">
          <input
            type="text"
            className="search-inputs"
            placeholder="Search By Creator"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <img
            src={`${process.env.PUBLIC_URL}/icons/Search.svg`}
            alt="Search"
            className="search-icon"
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="filter-btns" onClick={toggleModal}>
          <img
            src={`${process.env.PUBLIC_URL}/icons/Filter.svg`}
            alt="Filter"
            className="filter-icon"
          />
        </div>
      </div>

      {/* Transactions Table */}
      <div className="transactions-table-wrapper mt-4">
        <table className="transactions-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Creator</th>
              <th>Paid For</th>
              <th>Username</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {transactionsData.length > 0 && transactionsData.some(transaction => transaction.id !== null) ? (
              transactionsData
                .filter(transaction => transaction.id !== null)
                .map((transaction) => (
                  <tr key={transaction.id}>
                    <td>{transaction.formatted_date || 'N/A'}</td>
                    <td>{transaction.name || 'N/A'}</td>
                    <td>{transaction.type || 'N/A'}</td>
                    <td>{transaction.username || 'N/A'}</td>
                    <td>€{transaction.amount || '0.00'}</td>
                    <td>{transaction.status || 'Unknown'}</td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="6" style={{ textAlign: 'center' }}>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
                      alt="No Content Available"
                      style={{ width: '50%' }}
                    />
                    <p>No transactions available {searchTerm}</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>


        </table>
      </div>

      {/* Mobile Toggle View */}
      <div className="transactions-mobile-view">
        {transactionsData && transactionsData.length > 0 ? (
          transactionsData.map((transaction, index) => (
            <div className="m-gigs-purchase" key={transaction.id}>
              <div className="purchase-btn" onClick={() => toggleItem(index)}>
                <div className="text">
                  <h3>{transaction.username}</h3>
                </div>
                <div className="arrow">
                  <img
                    src={`${process.env.PUBLIC_URL}/icons/Arrow.svg`}
                    alt="toggle"
                    className={openItemIndex === index ? "arrow_up" : "arrow_down"}
                  />
                </div>
              </div>

              {/* Toggle content */}
              {openItemIndex === index && (
                <div className="m-purchase-body">
                  <div className="m-purchase-text">
                    <p>Date</p>
                    <h5>{transaction.formatted_date}</h5>
                  </div>
                  <div className="m-purchase-text">
                    <p>Creator</p>
                    <h5>{transaction.name}</h5>
                  </div>
                  <div className="m-purchase-text">
                    <p>Paid for</p>
                    <h5>{transaction.type}</h5>
                  </div>
                  <div className="m-purchase-text">
                    <p>Amount</p>
                    <h5>€{transaction.amount}</h5>
                  </div>
                  <div className="m-purchase-text">
                    <p>Status</p>
                    <h5>{transaction.status}</h5>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <img
              src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
              alt="No Transactions Available"
              style={{ width: '50%' }}
            />
            <p>No Transactions Available for {searchTerm}</p>
          </div>
        )}
      </div>

      {(nextPageUrl || prevPageUrl) && (
        <div className="pagination">
          <button
            className="pagination-btn"
            disabled={!prevPageUrl || currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            &lt;
          </button>

          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`pagination-btn ${currentPage === index + 1 ? "active" : ""}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}

          <button
            className="pagination-btn"
            disabled={!nextPageUrl || currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            &gt;
          </button>
        </div>
      )}


      {/* Modal Start */}
      <div className={`modal-container ${isModalOpen ? "show" : ""}`}>
        <div className="modal-content"  ref={modalRef}>
          <div className="modal-header">
            <h2>FILTERS</h2>
            <button className="close-btn" onClick={toggleModal}>
              <img
                src={`${process.env.PUBLIC_URL}/icons/Closebtn.svg`}
                alt="Close Button"
              />
            </button>
          </div>
          <div className="modal-body">
            <h4>Paid For</h4>
            <div className="checkbox-list">
              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  id="subscription"
                  checked={selectedFilters.includes("subscription")}
                  onChange={() => handleFilterChange("subscription")}
                />
                <label htmlFor="subscription">Subscription</label>
              </div>
              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  id="tips"
                  checked={selectedFilters.includes("tip")}
                  onChange={() => handleFilterChange("tip")}
                />
                <label htmlFor="tips">Tips</label>
              </div>
              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  id="unlock"
                  checked={selectedFilters.includes("unlock")}
                  onChange={() => handleFilterChange("unlock")}
                />
                <label htmlFor="unlock">Unlock</label>
              </div>
              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  id="gigs"
                  checked={selectedFilters.includes("gig")}
                  onChange={() => handleFilterChange("gig")}
                />
                <label htmlFor="gigs">Gigs</label>
              </div>
              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  id="Private gig "
                  checked={selectedFilters.includes("Private gig")}
                  onChange={() => handleFilterChange("Private gig")}
                />
                <label htmlFor="Private gig">Private Gig</label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="reset-btn"
              onClick={() => setSelectedFilters([])}
            >
              RESET
            </button>
            <button className="apply-btn" onClick={toggleModal}>
              APPLY FILTERS
            </button>
          </div>
        </div>
      </div>
      {/* Modal End */}
    </div>
  );
};

export default Transactions;
