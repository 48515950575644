import React, { useState, useEffect } from 'react';
import '../Notification/Notifaction.css';
import { api } from "../../utility/api";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const fetchNotifications = async (page = 1) => {
    try {
      const response = await api.get(`show_notification_data?page=${page}`);

      if (response.data.success) {
        setNotifications(response.data.new_notifications);
        setTotalPages(response.data.pagination.last_page);
        setNextPageUrl(response.data.pagination.next_page_url);
        setPrevPageUrl(response.data.pagination.prev_page_url);
        setError(null);
      } else {

        setError(response.data.message || 'An error occurred');
        setNotifications([]);
      }
      setLoading(false);
    } catch (error) {
      setError('No notifications available');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications(currentPage);
  }, [currentPage]);


  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="notification-page">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div style={{ textAlign: 'center' }}>
          <img
            src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
            alt="No Content Available"
            style={{ width: '50%' }}
          />
          <p style={{ marginTop: '20px', color: '#555' }}>{error}</p>
        </div>
      ) : notifications.length > 0 ? (
        <div className="notification-list">
          {notifications.map((notif, index) => (
            <div key={index} className="notification-card">
              {notif.from_user_name === 'null' && notif.from_user_avatar === 'null' && notif.to_user_name === 'null' && notif.message === 'null' ? (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
                    alt="No Content Available"
                    style={{ width: '50%' }}
                  />
                  <p style={{ marginTop: '20px', color: '#555' }}>No content available for this notification</p>
                </div>
              ) : (
                <>
                  <img src={notif.from_user_avatar !== 'null' ? notif.from_user_avatar : `${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`} alt={notif.from_user_name} className="avatar" />
                  <div className="notification-details">
                    <h6 style={{ marginBottom: "3px" }}>From: {notif.from_user_name !== 'null' ? notif.from_user_name : "No Name"}</h6>
                    <h6>To: {notif.to_user_name !== 'null' ? notif.to_user_name : "No Name"}</h6>
                    <p>{notif.message !== 'null' ? notif.message : "No Message Available"}</p>
                  </div>
                  <div className="notification-time">
                    <span>{notif.time_duration !== 'null' ? notif.time_duration : "N/A"}</span>
                  </div>
                </>
              )}
            </div>
          ))}


          {(nextPageUrl || prevPageUrl) && (
            <div className="pagination">
              <button
                className="pagination-btn"
                disabled={!prevPageUrl || currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                &lt;
              </button>

              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  className={`pagination-btn ${currentPage === index + 1 ? 'active' : ''}`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}

              <button
                className="pagination-btn"
                disabled={!nextPageUrl || currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                &gt;
              </button>
            </div>
          )}
        </div>
      ) : (
        <p>No notifications available</p>
      )}
    </div>
  );
};

export default Notifications;
