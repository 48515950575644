import React from 'react';

const Invitemanagers = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.heading}>Invitemanagers</h1>
      
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',  
    alignItems: 'center',      
    height: '100vh',           
    backgroundColor: '#f8f8f8',
  },
  content: {
    textAlign: 'center',      
  },
  heading: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: '#333',
  },
  paragraph: {
    fontSize: '1.2rem',
    color: '#666',
  },
};

export default Invitemanagers;
