
import { AuthActionTypes } from "./AuthActionTypes";
import axios from "axios";


const getUnhashedToken = async (hashedToken) => {
  try {
    // const response = await axios.get(`http://127.0.0.1:8000/api/get-token/${hashedToken}`);
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}get-token/${hashedToken}`);

    const token = response.data.token;  

    console.log("token", token);  
    return token;  

  } catch (error) {
    console.error('Error fetching unhashed token:', error);
    return null;  
  }
};

const fetchRoleName = async (token, role) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}feach_role/${role}`, {
      headers: {
        Authorization: `Bearer ${token}` 
      }
    });
    const roleName = response.data.role_name;
    console.log("Role Name fetched:", roleName);
    return roleName;
  } catch (error) {
    console.error('Error fetching role name:', error);
    return null;  
  }
};

// Login action
export const login = (hashedToken, role) => {
  return async (dispatch) => {
    try {
      const unhashedToken = await getUnhashedToken(hashedToken);
      console.log("unhashedToken Login Token", unhashedToken);

      if (unhashedToken) {
       
        const roleName = await fetchRoleName(unhashedToken, role);

        dispatch({
          type: AuthActionTypes.LOGIN_SUCCESS,
          payload: { token: unhashedToken, roleName: roleName }, 
        });
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };
};


// Logout action
export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.LOGOUT,
    });
  };
};