import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import {thunk} from 'redux-thunk';
import rootReducer from '../Auth/AuthReducer'; 



const store = createStore(rootReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

export { store, persistor };
