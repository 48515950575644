import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { api } from "../../utility/api";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Creators.css';
import { useSelector  } from 'react-redux';

const Creators = () => {
  const userRole = useSelector(state => state.role);
  const [errorMessage, setErrorMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [otpError, setOtpError] = useState(""); 
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  // const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [agencyCommission, setAgencyCommission] = useState('');

  const [otp, setOtp] = useState('');
  const [otpData, setOtpData] = useState({ id: null, agency_id: null, creator_id: null });


  const [creators, setCreators] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('subscriber_count');
  const [sortOrder, setSortOrder] = useState('desc');


  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);


  const handleShowModal1 = () => {
    setShowModal1(true);
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSubmit = async () => {

    setErrorMessage('');
    setFormErrors({});
    setLoading(true); 

    if ( !username || !email || !agencyCommission) {
      alert("Please fill in all fields");
      setLoading(false);
      return;
    }

    const requestData = {
      // name: fullName,
      username: username,
      email: email,
      agency_commission: agencyCommission
    };

    try {
      const response = await api.post('creator-otp', requestData);

      if (response.data.success) {
        const { id, agency_id, creator_id } = response.data.data;
        setOtpData({ id, agency_id, creator_id });

        setErrorMessage('');
        setFormErrors({});
        setShowModal1(false);
        setShowModal2(true);
      } else {
        if (response.data.message) {
          setErrorMessage(response.data.message);
        }
        setFormErrors(response.data.errors || {});
      }
    } catch (error) {
      if (error.response) {
        const { errors, message } = error.response.data;
        if (errors && Object.keys(errors).length > 0) {
          setFormErrors(errors);
          setErrorMessage('');
        } else {
          setErrorMessage(message || "An error occurred.");
        }
      } else if (error.request) {
        setErrorMessage("No response from the server.");
      } else {
        setErrorMessage("An error occurred: " + error.message);
      }
    }
    finally {
      setLoading(false); // Stop loading spinner after API call
    }
    
  };

  const handleOtpSubmit = async () => {
    const requestData = {
      id: otpData.id,
      agency_id: otpData.agency_id,
      creator_id: otpData.creator_id,
      otp: otp
    };

    try {
      const response = await api.post('verify-otp', requestData);
      if (response.data.success) {
        setShowModal2(false);
        fetchCreators();
        setOtpError("");
      } else {
        setErrorMessage(response.data.message || "OTP verification failed.");
        setOtpError(response.data.message || "Invalid OTP or member details."); 
      }
    } catch (error) {
      if (error.response) {
        setOtpError(error.response?.data.message || "An error occurred while verifying OTP.");
      } else {
        setErrorMessage("An error occurred: " + error.message);
      }
    }
  };

  const fetchCreators = useCallback(async (page = 1) => {
    setLoading(true);
    try {
      const response = await api.get(`show-creator?search=${searchQuery}&sort_by=${sortBy}&sort_order=${sortOrder}&page=${page}`);
      if (response.data.success) {
        const newCreators = response.data.creators;

        if (page === 1) {
          setCreators(newCreators);
        } else {
          setCreators((prev) => [...prev, ...newCreators]);
        }

        setCurrentPage(page);
        setHasMore(response.data.current_page < response.data.last_page);
      }
    } catch (error) {
      console.error("Error fetching creators: ", error);
    } finally {
      setLoading(false);
    }
  }, [searchQuery, sortBy, sortOrder]);


  useEffect(() => {
    fetchCreators(1);
  }, [fetchCreators]);

  const handleViewClick = (username) => {
    navigate(`/creatorprofile/${username}`);
  };



  return (
    <div className="container-fluid page-background">
      <div className="my-4">
        <div className="creators-container">
          <h5 className="creators-heading">Manage Creators profile</h5>
          {userRole !== 'sales' && (
          <button className="creators-button" onClick={handleShowModal1}>ONBOARD CREATOR</button>
          )}
        </div>

        {/* Search Bar and Sort Button */}
        <div className="d-flex mt-4" style={{ justifyContent: 'space-between', width: '100%' }}>
          <div className="search-container">

            <input
              type="text"
              className="search-input"
              placeholder="Search By Username"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button className="search-button">
              <img src={`${process.env.PUBLIC_URL}/icons/Search.svg`} alt="Search" className="menu-icon me-2" />
            </button>
          </div>

          {/* Sort Button */}
          <div className="dropdown">
            <button className="sort-button dropdown-toggle" onClick={toggleDropdown}>
              <img src={`${process.env.PUBLIC_URL}/icons/Sort.svg`} alt="Sort" className="menu-icon me-2" /> SORT
            </button>
            {dropdownOpen && (
              <div className="dropdown-menu show">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="sort"
                    value="subscriber_count_desc"
                    onChange={() => {
                      setSortBy('subscriber_count');
                      setSortOrder('desc');
                    }}
                  /> Top Subscribers
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="sort"
                    value="subscriber_count_asc"
                    onChange={() => {
                      setSortBy('subscriber_count');
                      setSortOrder('asc');
                    }}
                  /> Least Subscribers
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="sort"
                    value="follower_count_desc"
                    onChange={() => {
                      setSortBy('follower_count');
                      setSortOrder('desc');
                    }}
                  /> Top Followers
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="sort"
                    value="follower_count_asc"
                    onChange={() => {
                      setSortBy('follower_count');
                      setSortOrder('asc');
                    }}
                  /> Least Followers
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="sort"
                    value="total_earned_desc"
                    onChange={() => {
                      setSortBy('total_earned');
                      setSortOrder('desc');
                    }}
                  /> Highest Earnings
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="sort"
                    value="total_earned_asc"
                    onChange={() => {
                      setSortBy('total_earned');
                      setSortOrder('asc');
                    }}
                  /> Lowest Earnings
                </label>
              </div>
            )}
          </div>
        </div>

        {loading && (
          <div className="d-flex justify-content-center my-4">
            {/* <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner> */}
          </div>
        )}

        {/* Card Section */}
        {creators.length === 0 && !loading ? (
          <div className="no-content-available">
            <img
              src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
              alt="No Content Available"
              className="no-content-image"
            />
            <p>No posts available</p>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={creators.length}
            next={() => fetchCreators(currentPage + 1)}
            hasMore={hasMore}
            loader={
              <div className="d-flex justify-content-center my-4">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            }
            endMessage={<p style={{ textAlign: 'center' }} className="more">No More Creators To Display</p>}
          >
            <div className="card-container mt-2">
              {creators.map((creator) => (
                <div className="card" key={creator.id}>
                  <img src={creator.avatar} alt={creator.name} className="card-img" />
                  <h3 className="card-name">{creator.name} <p className="card-username_cretors">@{creator.username}</p>   </h3>



                  <div className="card-details">
                    <div className="card-info">
                      <img
                        src={`${process.env.PUBLIC_URL}/icons/Followers.svg`}
                        alt="Followers"
                        className="icon"
                      />
                      <span>{creator.formatted_follower_count} Followers</span>
                    </div>
                    <div className="card-info">
                      <img
                        src={`${process.env.PUBLIC_URL}/icons/Money.svg`}
                        alt="Earnings"
                        className="icon"
                      />
                      <span>€{creator.total_earned}</span>
                    </div>
                    <div className="card-info">
                      <img
                        src={`${process.env.PUBLIC_URL}/icons/Subcribers.svg`}
                        alt="Subscribers"
                        className="icon"
                      />
                      <span>{creator.lists_member_count} Subscribers</span>
                    </div>
                  </div>
                  <button
                    className="view-button"
                    onClick={() => handleViewClick(creator.username)}
                  >
                    VIEW
                  </button>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        )}




        {/* Modal 1 - Onboard Creator */}
        
        <Modal show={showModal1} onHide={handleCloseModal1} centered dialogClassName="custom-modal">
       
          <Modal.Header className="custom-modal-header">
            <Modal.Title>Onboard Creator</Modal.Title>
            <img src={`${process.env.PUBLIC_URL}/icons/cancel_o.svg`} alt="Close" onClick={handleCloseModal1} style={{ cursor: 'pointer' }} />
          </Modal.Header>
                  {errorMessage && (
                    <div className="error-message" style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
                      {errorMessage}
                    </div>
                  )}
                   
          <Modal.Body>
            <Form>
              {/* <Form.Group controlId="fullName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter full name"
                  className="input-field"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Form.Group> */}
              <Form.Group controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Add username"
                  className="input-field"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="emailId">
  <Form.Label>Email-id</Form.Label>
  <Form.Control
    type="email"
    placeholder="Enter your email-id"
    className="input-field"
    value={email}
    onChange={(e) => {
      setEmail(e.target.value);

    
      if (formErrors.email && e.target.value.includes("@")) {
        setFormErrors(prevErrors => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors.email; 
          return updatedErrors;
        });
      }
    }}
  />
  {formErrors.email && ( 
    <div className="error-message" style={{ color: 'red', marginTop: '5px' }}>
      {formErrors.email[0]} 
    </div>
  )}
</Form.Group>
<Form.Group controlId="agencyCommission">
  <Form.Label>Agency Commission</Form.Label>
  <Form.Control
    type="text"
    placeholder="Enter your agency commission"
    className="input-field"
    value={agencyCommission}
    onChange={(e) => {
      const value = e.target.value;
      setAgencyCommission(value);

  
      if (formErrors.agency_commission && parseFloat(value) <= 50) {
        setFormErrors(prevErrors => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors.agency_commission; 
          return updatedErrors;
        });
      }
    }}
  />
  {formErrors.agency_commission && (
    <div className="error-message" style={{ color: 'red', marginTop: '5px' }}>
      {formErrors.agency_commission[0]}
    </div>
  )}
</Form.Group>

            </Form>
          </Modal.Body>
          
          <Modal.Footer className="custom-modal-footer">
    <Button className="submit-button" onClick={handleSubmit} disabled={loading}>
      {loading ? (
        <Spinner animation="border" role="status" size="sm">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        "Submit"
      )}
    </Button>
  </Modal.Footer>

        </Modal>

        {/* Modal 2 - OTP Verification */}
        <Modal show={showModal2} onHide={handleCloseModal2} centered dialogClassName="custom-modal">
          <Modal.Header className="custom-modal-header">
            <Modal.Title>Verification</Modal.Title>
            <img src={`${process.env.PUBLIC_URL}/icons/cancel_o.svg`} alt="Close" onClick={handleCloseModal2} style={{ cursor: 'pointer' }} />
          </Modal.Header>
          <Modal.Body>
            <Form>
              {/* <Form.Group controlId="otp">
                <Form.Label>Enter OTP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your OTP"
                  className="input-field"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <div className="resend-container">
                  <span className="resend-link" onClick={handleSubmit}>Resend</span>
                </div>
              </Form.Group> */}
               <Form.Group controlId="otp">
              <Form.Label>Enter OTP</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your OTP"
                className="input-field"
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                  if (otpError) {
                    setOtpError(""); 
                  }
                }}
                isInvalid={!!otpError}
              />
              {otpError && (
                <Form.Control.Feedback type="invalid">
                  {otpError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="custom-modal-footer">
            <Button className="submit-button" onClick={handleOtpSubmit}>Submit OTP</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Creators;
