import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Creatorprofile.css";
import Header from "../../component/Header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import { api } from "../../utility/api";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from 'react-bootstrap';

import { useSelector  } from 'react-redux';

const Creatorprofile = () => {
  const userRole = useSelector(state => state.role);
  const { username } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("Profile");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("posts");
  const [openItemIndex, setOpenItemIndex] = useState(null);

  const [creatorProfile, setCreatorProfile] = useState(null);

  const [posts, setPosts] = useState([]);
  const [postsCurrentPage, setPostsCurrentPage] = useState(1);
  const [postsTotalPages, setPostsTotalPages] = useState(1);
  const [hasMorePosts, setHasMorePosts] = useState(true);

  const [gigs, setGigs] = useState([]);
  const [gigsCurrentPage, setGigsCurrentPage] = useState(1);
  const [gigsTotalPages, setGigsTotalPages] = useState(1);
  const [hasMoreGigs, setHasMoreGigs] = useState(true);

  const [ongoingJobs, setOngoingJobs] = useState([]);
  const [ongoingJobsCurrentPage, setOngoingJobsCurrentPage] = useState(1);
  const [ongoingJobsTotalPages, setOngoingJobsTotalPages] = useState(1);
  const [ongoingJobsNextPageUrl, setOngoingJobsNextPageUrl] = useState(null);
  const [ongoingJobsPrevPageUrl, setOngoingJobsPrevPageUrl] = useState(null);


  const [earnings, setEarnings] = useState([]);
  const [earningsCurrentPage, setEarningsCurrentPage] = useState(1);
  const [earningsTotalPages, setEarningsTotalPages] = useState(1);
  const [earningsNextPageUrl, setEarningsNextPageUrl] = useState(null);
  const [earningsPrevPageUrl, setEarningsPrevPageUrl] = useState(null);


  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);

  const toggleItem = (index) => {
    setOpenItemIndex(openItemIndex === index ? null : index);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // For The Creator Profile Name
  useEffect(() => {
    const fetchCreatorProfile = async () => {
      try {
        const response = await api.get(`creator-data/${username}`);
        const data = response.data;
        if (data.success) {
          setCreatorProfile(data.creator_profile);
        }
      } catch (error) {
        console.error("Error fetching creator profile:", error);
      }
    };

    fetchCreatorProfile();
  }, [username]);

  useEffect(() => {
    const fetchCreatorData = async () => {
      try {
        let url = `creator-data/${username}?posts_page=${postsCurrentPage}&gigs_page=${gigsCurrentPage}&earning_page=${earningsCurrentPage}&ongoing_jobs_page=${ongoingJobsCurrentPage}`;

        if (searchTerm) {
          url += `&search=${searchTerm}`;
        }

        if (selectedFilters.length > 0) {
          const filters = selectedFilters.map(filter => `filter_type[]=${filter}`).join("&");
          url += `&${filters}`;
        }

        const response = await api.get(url);
        const data = response.data;

        if (data.success) {

          setPosts((prevPosts) => {
            const newPosts = data.posts.data.filter(
              (newPost) => !prevPosts.some((post) => post.id === newPost.id)
            );
            return [...prevPosts, ...newPosts];
          });
          setPostsTotalPages(data.posts.pagination.last_page);
          setHasMorePosts(postsCurrentPage < data.posts.pagination.last_page);


          setGigs((prevGigs) => {
            const newGigs = data.gigs.data.filter(
              (newGig) => !prevGigs.some((gig) => gig.id === newGig.id)
            );
            return [...prevGigs, ...newGigs];
          });
          setGigsTotalPages(data.gigs.pagination.last_page);
          setHasMoreGigs(gigsCurrentPage < data.gigs.pagination.last_page);



          setOngoingJobs(data.ongoing_jobs.data || []);
          setOngoingJobsTotalPages(data.ongoing_jobs.pagination.last_page);
          setOngoingJobsNextPageUrl(data.ongoing_jobs.pagination.next_page_url);
          setOngoingJobsPrevPageUrl(data.ongoing_jobs.pagination.prev_page_url);



          setEarnings(data.earning.data || []);
          setEarningsTotalPages(data.earning.pagination.last_page);
          setEarningsNextPageUrl(data.earning.pagination.next_page_url);
          setEarningsPrevPageUrl(data.earning.pagination.prev_page_url);

        }
      } catch (error) {
        console.error("Error fetching creator data:", error);
      }
    };

    fetchCreatorData();
  }, [postsCurrentPage,
    gigsCurrentPage, ongoingJobsCurrentPage, earningsCurrentPage, username, searchTerm, selectedFilters]);

  const fetchMorePosts = async () => {
    if (postsCurrentPage < postsTotalPages) {
      setPostsCurrentPage((prevPage) => prevPage + 1);
    } else {
      setHasMorePosts(false);
    }
  };

  const fetchMoreGigs = async () => {
    if (gigsCurrentPage < gigsTotalPages) {
      setGigsCurrentPage((prevPage) => prevPage + 1);
    } else {
      setHasMoreGigs(false);
    }
  };




  const handleOngoingJobsPageChange = (page) => {
    if (page >= 1 && page <= ongoingJobsTotalPages) {
      setOngoingJobsCurrentPage(page);
    }
  };


  const handleEarningsPageChange = (page) => {
    if (page >= 1 && page <= earningsTotalPages) {
      setEarningsCurrentPage(page);
    }
  };


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (filter) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter((item) => item !== filter));
    } else {
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  const resetFilters = () => {
    setSelectedFilters([]);
  };

  // CREATOR ACCOUNT button click
  const handleCreatorAccountClick = async () => {
    try {
      const response = await api.get(`get-login-data-and-redirect/${username}`);
      if (response.data.success) {
        const loginUrl = response.data.login_url;
        window.location.href = loginUrl;
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching login URL:", error);
      alert("Error fetching login URL. Please try again.");
    }
  };

  return (
    <>
      <Header creatorName={creatorProfile ? creatorProfile.name : ""} />

      <div className="creator-profile">
        {/* Header Section */}
        <div className="header-container">
          <div className="back-buttons">
            <img
              src={`${process.env.PUBLIC_URL}/icons/Backspace.svg`}
              alt="Back"
              onClick={() => {
                console.log("Back button clicked");
                navigate("/creators");
              }}
            />

          </div>
          <div className="profile-name">
            {creatorProfile ? creatorProfile.name : "Loading..."}
          </div>
          {userRole !== 'sales' && (
          <button
            className="creator-account-btn"
            onClick={handleCreatorAccountClick}
          >
            CREATOR ACCOUNT
          </button>
          )}
        </div>

        {/* Tab Navigation */}
        <div className="tab-bar">
          <div
            className={`tab-item ${activeTab === "Profile" ? "active" : ""}`}
            onClick={() => handleTabClick("Profile")}
          >
            Profile
          </div>
          <div
            className={`tab-item ${activeTab === "Posts and Gigs" ? "active" : ""
              }`}
            onClick={() => handleTabClick("Posts and Gigs")}
          >
            Posts and Gigs
          </div>
          <div
            className={`tab-item ${activeTab === "Ongoing Jobs" ? "active" : ""
              }`}
            onClick={() => handleTabClick("Ongoing Jobs")}
          >
            Ongoing Jobs
          </div>
          <div
            className={`tab-item ${activeTab === "Earnings" ? "active" : ""}`}
            onClick={() => handleTabClick("Earnings")}
          >
            Earnings
          </div>
        </div>

        {/* Tab Content */}
        <div className="tab-content">
          {/* Profile Start */}
          {activeTab === "Profile" && creatorProfile && (
            <>
              {/* Profile Section Start */}
              <div className="profile-container">
                {/* Profile Header */}
                <div className="profile-header-container">
                  <div className="profile-header-title">Profile</div>
                </div>

                <div className="profile-header">
                  <img
                    src={creatorProfile.cover || "../img/Demo2.svg"}
                    alt="Background"
                    className="profile-background"
                  />
                  <img
                    src={creatorProfile.avatar || "../img/Demo3.svg"}
                    alt="Profile"
                    className="profile-picture"
                  />
                </div>

                <div className="profile-info">
                  <h2>{creatorProfile.name || "No Name Available"}</h2>
                  <p>@{creatorProfile.username}</p>
                  <p className="profile-bio">
                    {creatorProfile.bio || "No bio available"}
                  </p>
                </div>

                <div className="profile-stats">
                  <div className="stat">
                    <img src={`${process.env.PUBLIC_URL}/icons/Followers.svg`} alt="Followers" />
                    <p>
                      <strong>{creatorProfile.follower_count}</strong> Followers
                    </p>
                  </div>
                  <div className="stat">
                    <img src={`${process.env.PUBLIC_URL}/icons/Posts.svg`} alt="Posts" />
                    <p>
                      <strong>{creatorProfile.post_count}</strong> Posts
                    </p>
                  </div>
                  <div className="stat">
                    <img src={`${process.env.PUBLIC_URL}/icons/LiveGigs.svg`} alt="Live Gigs" />
                    <p>
                      <strong>{creatorProfile.gig_count}</strong> Live Gigs
                    </p>
                  </div>
                  <div className="stat">
                    <img src={`${process.env.PUBLIC_URL}/icons/Subcribers.svg`} alt="Subscribers" />
                    <p>
                      <strong>{creatorProfile.subscriber_count}</strong>{" "}
                      Subscribers
                    </p>
                  </div>
                </div>
              </div>
              {/* Profile Section End */}

              {/* Personal Details Start */}
              <div className="profile-container">
                <div className="personal-details-container">
                  <div className="personal-header-container">
                    <div className="personal-header-title">
                      Personal Details
                    </div>
                  </div>

                  <div className="personal-details-form">
                    <div className="personal-input-group">
                      <label>Username</label>
                      <input
                        type="text"
                        value={creatorProfile.username}
                        readOnly
                      />
                    </div>
                    <div className="personal-input-group">
                      <label>Full Name</label>
                      <input
                        type="text"
                        value={creatorProfile.name || "Not Available"}
                        readOnly
                      />
                    </div>
                    <div className="personal-input-group">
                      <label>Gender</label>
                      <input
                        type="text"
                        value={creatorProfile.gender_pronoun || "Not Specified"}
                        readOnly
                      />
                    </div>
                    <div className="personal-input-group">
                      <label>Orientation</label>
                      <input
                        type="text"
                        value={
                          creatorProfile.sexual_preference || "Not Specified"
                        }
                        readOnly
                      />
                    </div>
                    <div className="personal-input-group">
                      <label>Height</label>
                      <input
                        type="text"
                        value={creatorProfile.height || "Not Specified"}
                        readOnly
                      />
                    </div>
                    <div className="personal-input-group">
                      <label>Weight</label>
                      <input
                        type="text"
                        value={creatorProfile.weight || "Not Specified"}
                        readOnly
                      />
                    </div>
                    <div className="personal-input-group">
                      <label>Eye Color</label>
                      <input
                        type="text"
                        value={creatorProfile.eye_color || "Not Specified"}
                        readOnly
                      />
                    </div>
                    <div className="personal-input-group">
                      <label>Hair Color</label>
                      <input
                        type="text"
                        value={creatorProfile.hair_color || "Not Specified"}
                        readOnly
                      />
                    </div>
                    <div className="personal-input-group">
                      <label>Tattoo</label>
                      <input
                        type="text"
                        value={creatorProfile.tattoo || "Not Specified"}
                        readOnly
                      />
                    </div>
                    <div className="personal-input-group">
                      <label>Piercing</label>
                      <input
                        type="text"
                        value={creatorProfile.piercing || "Not Specified"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Personal Details End */}

              {/* Social Media Links Start */}
              <div className="profile-container">
                <div className="social-media-container">
                  <div className="social-header-container">
                    <div className="social-header-title">
                      Social Media Links
                    </div>
                  </div>

                  <form className="social-media-form">
                    <div className="social-input-group">
                      <label>Youtube</label>
                      <input
                        type="text"
                        value={creatorProfile.youtube || "Not Available"}
                        readOnly
                      />
                    </div>

                    <div className="social-input-group">
                      <label>TikTok</label>
                      <input
                        type="text"
                        value={creatorProfile.tiktok || "Not Available"}
                        readOnly
                      />
                    </div>

                    <div className="social-input-group">
                      <label>Instagram</label>
                      <input
                        type="text"
                        value={creatorProfile.instagram || "Not Available"}
                        readOnly
                      />
                    </div>

                    <div className="social-input-group">
                      <label>Snapchat</label>
                      <input
                        type="text"
                        value={creatorProfile.snapchat || "Not Available"}
                        readOnly
                      />
                    </div>
                  </form>
                </div>
              </div>
              {/* Social Media Links End */}
            </>
          )}
          {/* Profile End */}

          {/* Posts and Gigs Content Start */}
          {activeTab === "Posts and Gigs" && (
            <div className="toggle-container">
              <div className="toggle-header">
                <h2 className="left-header">Creator's Posts</h2>
                <div className="tabs">
                  <button
                    className={`tab ${selectedTab === "posts" ? "active" : ""}`}
                    onClick={() => setSelectedTab("posts")} posts
                  >
                    POSTS
                  </button>
                  <button
                    className={`tab ${selectedTab === "gigs" ? "active" : ""}`}
                    onClick={() => setSelectedTab("gigs")}
                    style={{ borderRadius: "0px 16px 16px 0px" }}
                  >
                    GIGS
                  </button>
                </div>
              </div>

              <div className="contents">
                {selectedTab === "posts" ? (
                  <InfiniteScroll
                    dataLength={posts.length}
                    next={fetchMorePosts}
                    hasMore={hasMorePosts}
                    loader={
                      <div className="d-flex justify-content-center my-4">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    }
                    endMessage={<p style={{ textAlign: 'center' }}>No More Posts Available</p>}
                  >
                    <div className="container mt-2">
                      <div className="row">
                        {posts && posts.length > 0 ? (
                          posts.map((post, index) => (
                            <div className="col-md-6" key={index}>
                              {post.id === null ||
                                post.title === "null" ||
                                post.text === "null" ||
                                post.user.name === "null" ? (
                                <div
                                  className="no-content"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
                                    alt="No Content Available"
                                  />
                                  <p>No posts available</p>
                                </div>
                              ) : (
                                <div className="custom-card">
                                  <div className="custom-card-header d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={
                                          post.user.avatar !== "null"
                                            ? post.user.avatar
                                            : "/path-to/NoContentAvail.svg"
                                        }
                                        alt="Profile"
                                        className="rounded-circle"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                      <div className="ml-3 mt-3">
                                        <strong>
                                          {post.user.name !== "null"
                                            ? post.user.name
                                            : "No Name Available"}
                                        </strong>
                                        <p className="text-muted">
                                          @
                                          {post.user.username !== "null"
                                            ? post.user.username
                                            : "No Username Available"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="text-muted">
                                      {post.formatted_date !== "null"
                                        ? post.formatted_date
                                        : "No Date Available"}
                                    </div>
                                  </div>

                                  <div className="custom-card-body">
                                    <p className="custom-card-text">
                                      {post.text !== "null"
                                        ? post.text
                                        : "No Content Available"}
                                    </p>
                                    {post.attachments.length > 0 &&
                                      post.attachments[0].path !== "null" ? (
                                      <img
                                        src={post.attachments[0].path}
                                        className="custom-card-img-top"
                                        alt="Post"
                                      />
                                    ) : (
                                      <img
                                        src="/path-to/NoContentAvail.svg"
                                        alt="No Content Available"
                                      />
                                    )}
                                  </div>

                                  <div className="custom-card-footer d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                      <span className="mr-3 d-flex align-items-center">
                                        <img
                                          src={`${process.env.PUBLIC_URL}/icons/Like.svg`}
                                          alt="Like"
                                          className="mr-2"
                                        />{" "}
                                        {post.like_count}
                                      </span>
                                      <span className="mr-3 d-flex align-items-center">
                                        <img
                                          src={`${process.env.PUBLIC_URL}/icons/Focus.svg`}
                                          alt="Views"
                                          className="mr-2"
                                        />{" "}
                                        {post.payment_count}
                                      </span>
                                      <span className="d-flex align-items-center">
                                        <img
                                          src={`${process.env.PUBLIC_URL}/icons/Chat.svg`}
                                          alt="Comments"
                                          className="mr-2"
                                        />{" "}
                                        {post.comments_count}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div>
                            <p>No posts available</p>
                          </div>
                        )}
                      </div>
                      {/* Posts Pagination */}


                    </div>
                  </InfiniteScroll>
                ) : (
                  <InfiniteScroll
                    dataLength={gigs.length}
                    next={fetchMoreGigs}
                    hasMore={hasMoreGigs}
                    loader={
                      <div className="d-flex justify-content-center my-4">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    }
                    endMessage={<p style={{ textAlign: 'center' }}>No More Gigs Available</p>}
                  >
                    <div className="container mt-4">
                      <div className="row">

                        {gigs && gigs.length > 0 ? (

                          gigs.map((gig, index) => (
                            <div className="col-md-4" key={index}>
                              {gig.id === null ||
                                gig.title === "null" ||
                                gig.user.name === "null" ||
                                gig.user.username === "null" ||
                                !gig.attachments ||
                                !gig.attachments[0] ? (
                                <div className="no-content" style={{ textAlign: "center" }}>
                                  <img
                                    src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
                                    alt="No Content Available"
                                  />
                                  <p>No gigs available</p>
                                </div>
                              ) : (
                                <div className="content-box">
                                  <div className="content-img-container">
                                    {gig.attachments.length > 0 && gig.attachments[0].thumbnail !== "null" ? (
                                      <img
                                        src={gig.attachments[0].thumbnail}
                                        className="content-img"
                                        alt="Gig"
                                      />
                                    ) : (
                                      <img
                                        src={`${process.env.PUBLIC_URL}/icons/NoThumbnail.svg`}
                                        alt="No Thumbnail"
                                      />
                                    )}
                                  </div>

                                  <div className="content-body">
                                    <div className="profile-section d-flex align-items-center mb-2">
                                      <img
                                        src={
                                          gig.user.avatar !== "null" && gig.user.avatar
                                            ? gig.user.avatar
                                            : `${process.env.PUBLIC_URL}/icons/DefaultProfile.svg`
                                        }
                                        alt="Profile"
                                        className="rounded-circle"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                      <div className="ml-3 mt-3">
                                        <strong>
                                          {gig.user.name !== "null" ? gig.user.name : "No Name Available"}
                                        </strong>
                                        <p className="text-muted">
                                          @{gig.user.username !== "null" ? gig.user.username : "No Username Available"}
                                        </p>
                                      </div>
                                    </div>

                                    <h5 className="content-title">
                                      {gig.title !== "null" ? gig.title : "No Title"}
                                    </h5>

                                    <div className="content-card-footer">
                                      <div className="icons d-flex justify-content-between">
                                        <span>
                                          <img src={`${process.env.PUBLIC_URL}/icons/Like.svg`} alt="Like" /> {gig.like_count}
                                        </span>
                                        <span>
                                          <img src={`${process.env.PUBLIC_URL}/icons/Focus.svg`} alt="Views" /> {gig.total_requests}
                                        </span>
                                        <span>
                                          <img src={`${process.env.PUBLIC_URL}/icons/Chat.svg`} alt="Comments" /> {gig.comments_count}
                                        </span>
                                      </div>

                                      <button className="buy-button">
                                        BUY @ €
                                        {gig.price !== "null" ? gig.price : "No Price Available"}
                                      </button>
                                    </div>
                                  </div>


                                </div>
                              )}
                            </div>

                          ))
                        ) : (
                          <div>
                            <p>No gigs available</p>
                          </div>

                        )}

                      </div>
                      {/* Gig Pagination */}

                    </div>
                  </InfiniteScroll>
                )}
              </div>

            </div>

          )}
          {/* Posts and Gigs Content End */}

          {/* Ongoing Jobs Start*/}
          {activeTab === "Ongoing Jobs" && (
            <>
              {/* Desktop Table View */}
              <div className="table-view">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th>Gig Title</th>
                        <th>Hired By</th>
                        <th>Start Date</th>
                        <th>Price</th>
                        <th>Submission Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ongoingJobs.length > 0 &&
                        ongoingJobs.some(
                          (job) => job.private_gig.gig_title !== "null"
                        ) ? (
                        ongoingJobs.map((job, index) => (
                          <tr key={index}>
                            <td>
                              {job.private_gig.gig_title !== "null"
                                ? job.private_gig.gig_title
                                : "No Title"}
                            </td>
                            <td>
                              {job.buyer.name !== "null"
                                ? job.buyer.name
                                : "No Name"}
                            </td>
                            <td>
                              {job.formatted_start_date !== "null"
                                ? job.formatted_start_date
                                : "No Start Date"}
                            </td>
                            <td>
                              {job.formatted_gig_budget !== "null"
                                ? job.formatted_gig_budget
                                : "No Price"}
                            </td>
                            <td>
                              {job.formatted_submission_date !== "null"
                                ? job.formatted_submission_date
                                : "No Submission Date"}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">
                            <div
                              className="no-content"
                              style={{ textAlign: "center" }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
                                alt="No Content Available"
                              />
                              <p>No Ongoing Jobs</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {(ongoingJobsNextPageUrl || ongoingJobsPrevPageUrl) && (
                  <div className="pagination">
                    <button
                      className="pagination-btn"
                      disabled={!ongoingJobsPrevPageUrl || ongoingJobsCurrentPage === 1}
                      onClick={() => handleOngoingJobsPageChange(ongoingJobsCurrentPage - 1)}
                    >
                      &lt;
                    </button>

                    {[...Array(ongoingJobsTotalPages)].map((_, index) => (
                      <button
                        key={index}
                        className={`pagination-btn ${ongoingJobsCurrentPage === index + 1 ? "active" : ""
                          }`}
                        onClick={() => handleOngoingJobsPageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}

                    <button
                      className="pagination-btn"
                      disabled={!ongoingJobsNextPageUrl || ongoingJobsCurrentPage === ongoingJobsTotalPages}
                      onClick={() => handleOngoingJobsPageChange(ongoingJobsCurrentPage + 1)}
                    >
                      &gt;
                    </button>
                  </div>
                )}


              </div>

              {/* Mobile Toggle View */}
              <div className="toggle-view">
                {ongoingJobs.length > 0 &&
                  ongoingJobs.some(
                    (job) => job.private_gig.gig_title !== "null"
                  ) ? (
                  ongoingJobs.map((job, index) => (
                    <div
                      className="m-job-item"
                      key={index}
                      onClick={() => toggleItem(index)}
                    >
                      <div className="m-job-header">
                        <div className="m-job-title">
                          {job.private_gig.gig_title !== "null"
                            ? job.private_gig.gig_title
                            : "No Title"}
                        </div>
                        <div className="m-job-arrow">
                          <img
                            src={`${process.env.PUBLIC_URL}/icons/Arrow.svg`}
                            alt="Toggle Arrow"
                            className={
                              openItemIndex === index
                                ? "arrow-up"
                                : "arrow-down"
                            }
                          />
                        </div>
                      </div>
                      {openItemIndex === index && (
                        <div className="m-job-details">
                          <div className="m-job-detail-row">
                            <div className="m-detail-title">Hired By:</div>
                            <div className="m-detail-value">
                              {job.buyer.name !== "null"
                                ? job.buyer.name
                                : "No Name"}
                            </div>
                          </div>
                          <div className="m-job-detail-row">
                            <div className="m-detail-title">Start Date:</div>
                            <div className="m-detail-value">
                              {job.formatted_start_date !== "null"
                                ? job.formatted_start_date
                                : "No Start Date"}
                            </div>
                          </div>
                          <div className="m-job-detail-row">
                            <div className="m-detail-title">Price:</div>
                            <div className="m-detail-value">
                              {job.formatted_gig_budget !== "null"
                                ? job.formatted_gig_budget
                                : "No Price"}
                            </div>
                          </div>
                          <div className="m-job-detail-row">
                            <div className="m-detail-title">
                              Submission Date:
                            </div>
                            <div className="m-detail-value">
                              {job.formatted_submission_date !== "null"
                                ? job.formatted_submission_date
                                : "No Submission Date"}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="no-content" style={{ textAlign: "center" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
                      alt="No Content Available"
                    />
                    <p>No Ongoing Jobs</p>
                  </div>
                )}
                {(ongoingJobsNextPageUrl || ongoingJobsPrevPageUrl) && (
                  <div className="pagination">
                    <button
                      className="pagination-btn"
                      disabled={!ongoingJobsPrevPageUrl || ongoingJobsCurrentPage === 1}
                      onClick={() => handleOngoingJobsPageChange(ongoingJobsCurrentPage - 1)}
                    >
                      &lt;
                    </button>

                    {[...Array(ongoingJobsTotalPages)].map((_, index) => (
                      <button
                        key={index}
                        className={`pagination-btn ${ongoingJobsCurrentPage === index + 1 ? "active" : ""
                          }`}
                        onClick={() => handleOngoingJobsPageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}

                    <button
                      className="pagination-btn"
                      disabled={!ongoingJobsNextPageUrl || ongoingJobsCurrentPage === ongoingJobsTotalPages}
                      onClick={() => handleOngoingJobsPageChange(ongoingJobsCurrentPage + 1)}
                    >
                      &gt;
                    </button>
                  </div>
                )}
              </div>

            </>
          )}
          {/* Ongoing Jobs End */}

          {/* Earnings Start */}
          {activeTab === "Earnings" && (
            <>
              <div className="search-filter-container_profile">
                {/* Search Bar */}
                <div className="search-bar">
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Search by username"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/icons/Search.svg`}
                      alt="Search"
                      className="Search"
                      style={{ marginRight: "16px" }}
                    />
                  </div>
                </div>

                {/* Filter Button */}
                <div className="filter-btn" onClick={toggleModal}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icons/Filter.svg`}
                    alt="Filter"
                    className="Filter"
                  />
                </div>
              </div>

              {/* Earnings Table */}
              <div className="table-view">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th>Date</th>
                        <th>Username</th>
                        <th>Paid For</th>
                        <th>Mode</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {earnings &&
                        earnings.length > 0 &&
                        earnings.some((earning) => earning.id !== null) ? (
                        earnings.map((earning, index) => (
                          <tr key={index}>
                            <td>
                              {earning.formatted_date !== "null"
                                ? earning.formatted_date
                                : "No Date"}
                            </td>
                            <td>
                              {earning.sender_name !== "null"
                                ? earning.sender_name
                                : "No Username"}
                            </td>
                            <td>
                              {earning.type !== "null"
                                ? earning.type
                                : "No Type"}
                            </td>
                            <td>
                              {earning.payment_provider !== "null"
                                ? earning.payment_provider
                                : "No Mode"}
                            </td>
                            <td>
                              €
                              {earning.formatted_amount !== "null"
                                ? earning.formatted_amount
                                : "No Amount"}
                            </td>
                            <td>
                              {earning.status !== "null"
                                ? earning.status
                                : "No Status"}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">
                            <div
                              className="no-content"
                              style={{ textAlign: "center" }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
                                alt="No Content Available"
                              />
                              <p>No Earnings Available</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>


              </div>

              {/* Mobile Toggle View */}
              <div className="toggle-view">
                {earnings &&
                  earnings.length > 0 &&
                  earnings.some((earning) => earning.id !== null) ? (
                  earnings.map((earning, index) => (
                    <div
                      className="m-job-item"
                      key={index}
                      onClick={() => toggleItem(index)}
                    >
                      <div className="m-job-header">
                        <div className="m-job-title">
                          {earning.sender_name !== "null"
                            ? earning.sender_name
                            : "No Username"}
                        </div>
                        <div className="m-job-arrow">
                          <img
                            src={`${process.env.PUBLIC_URL}/icons/Arrow.svg`}
                            alt="Toggle Arrow"
                            className={
                              openItemIndex === index
                                ? "arrow-up"
                                : "arrow-down"
                            }
                          />
                        </div>
                      </div>
                      {openItemIndex === index && (
                        <div className="m-job-details">
                          <div className="m-job-detail-row">
                            <div className="m-detail-title">Date</div>
                            <div className="m-detail-value">
                              {earning.formatted_date !== "null"
                                ? earning.formatted_date
                                : "No Date"}
                            </div>
                          </div>
                          <div className="m-job-detail-row">
                            <div className="m-detail-title">Paid For</div>
                            <div className="m-detail-value">
                              {earning.type !== "null"
                                ? earning.type
                                : "No Type"}
                            </div>
                          </div>
                          <div className="m-job-detail-row">
                            <div className="m-detail-title">Mode</div>
                            <div className="m-detail-value">
                              {earning.payment_provider !== "null"
                                ? earning.payment_provider
                                : "No Mode"}
                            </div>
                          </div>
                          <div className="m-job-detail-row">
                            <div className="m-detail-title">Amount</div>
                            <div className="m-detail-value">
                              €
                              {earning.formatted_amount !== "null"
                                ? earning.formatted_amount
                                : "No Amount"}
                            </div>
                          </div>
                          <div className="m-job-detail-row">
                            <div className="m-detail-title">Status</div>
                            <div className="m-detail-value">
                              {earning.status !== "null"
                                ? earning.status
                                : "No Status"}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="no-content" style={{ textAlign: "center" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
                      alt="No Content Available"
                    />
                    <p>No Earnings Available</p>
                  </div>
                )}
                {(earningsNextPageUrl || earningsPrevPageUrl) && (
                  <div className="pagination">
                    <button
                      className="pagination-btn"
                      disabled={!earningsPrevPageUrl || earningsCurrentPage === 1}
                      onClick={() => handleEarningsPageChange(earningsCurrentPage - 1)}
                    >
                      &lt;
                    </button>

                    {[...Array(earningsTotalPages)].map((_, index) => (
                      <button
                        key={index}
                        className={`pagination-btn ${earningsCurrentPage === index + 1 ? "active" : ""
                          }`}
                        onClick={() => handleEarningsPageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}

                    <button
                      className="pagination-btn"
                      disabled={!earningsNextPageUrl || earningsCurrentPage === earningsTotalPages}
                      onClick={() => handleEarningsPageChange(earningsCurrentPage + 1)}
                    >
                      &gt;
                    </button>
                  </div>
                )}
              </div>

              {/* Pagination */}
              {(earningsNextPageUrl || earningsPrevPageUrl) && (
                <div className="pagination">
                  <button
                    className="pagination-btn"
                    disabled={!earningsPrevPageUrl || earningsCurrentPage === 1}
                    onClick={() => handleEarningsPageChange(earningsCurrentPage - 1)}
                  >
                    &lt;
                  </button>

                  {[...Array(earningsTotalPages)].map((_, index) => (
                    <button
                      key={index}
                      className={`pagination-btn ${earningsCurrentPage === index + 1 ? "active" : ""
                        }`}
                      onClick={() => handleEarningsPageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}

                  <button
                    className="pagination-btn"
                    disabled={!earningsNextPageUrl || earningsCurrentPage === earningsTotalPages}
                    onClick={() => handleEarningsPageChange(earningsCurrentPage + 1)}
                  >
                    &gt;
                  </button>
                </div>
              )}

              {/* Modal for Filters */}
              <div className={`modal-container ${isModalOpen ? "show" : ""}`}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h2>FILTERS</h2>
                    <button className="close-btn" onClick={toggleModal}>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons/Closebtn.svg`}
                        alt="Close Button"
                      />
                    </button>
                  </div>
                  <div className="modal-body">
                    <h4>Paid For</h4>
                    <div className="checkbox-list">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="subscription"
                          checked={selectedFilters.includes("subscription")}
                          onChange={() => handleFilterChange("subscription")}
                        />
                        <label htmlFor="subscription">Subscription</label>
                      </div>
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="tip"
                          checked={selectedFilters.includes("tip")}
                          onChange={() => handleFilterChange("tip")}
                        />
                        <label htmlFor="tip">Tips</label>
                      </div>
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="unlock"
                          checked={selectedFilters.includes("unlock")}
                          onChange={() => handleFilterChange("unlock")}
                        />
                        <label htmlFor="unlock">Unlock</label>
                      </div>
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="Private gig"
                          checked={selectedFilters.includes("Private gig")}
                          onChange={() => handleFilterChange("Private gig")}
                        />
                        <label htmlFor="Private gig">Private gig</label>
                      </div>
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="gig"
                          checked={selectedFilters.includes("gig")}
                          onChange={() => handleFilterChange("gig")}
                        />
                        <label htmlFor="gig">Gig</label>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button className="reset-btn" onClick={resetFilters}>
                      RESET
                    </button>
                    <button className="apply-btn" onClick={toggleModal}>
                      APPLY FILTERS
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* Model Code End */}
        </div>

      </div>
    </>
  );
};

export default Creatorprofile;
