import React, { useState, useEffect, useCallback , useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../RevenueSplits/RevenueSplits.css';
import { Line } from 'react-chartjs-2';
import Dropdown from 'react-bootstrap/Dropdown';
import 'chart.js/auto';
import { api } from "../../utility/api";

const RevenueSplits = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openItemIndex, setOpenItemIndex] = useState(null);
  const [revenueData, setRevenueData] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState('1 Month');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const [nextPageUrl, setNextPageUrl] = useState(null); // Next page URL
  const [prevPageUrl, setPrevPageUrl] = useState(null); // Previous page URL


  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const modalRef = useRef(null);

  // Detect click outside modal to close it
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      // Add event listener to detect clicks outside the modal
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Remove event listener when the modal is closed
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);


  const timeRanges = [
    { label: '1 Week', filter: 'week' },
    { label: '1 Month', filter: 'month' },
    { label: 'Last 3 Months', filter: '3_months' },
    { label: 'Last 6 Months', filter: '6_months' },
    { label: 'Last 1 Year', filter: 'year' },
  ];


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  const toggleItem = (index) => {
    setOpenItemIndex(openItemIndex === index ? null : index);
  };


  const handleFilterChange = (filter) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter((item) => item !== filter));
    } else {
      setSelectedFilters([...selectedFilters, filter]);
    }
  };


  const handleDropdownClick = async (range) => {
    setSelectedTimeRange(range.label);

    try {
      const response = await api.get(`revenue-chart?filter=${range.filter}`);
      if (response.data && response.data.success) {
        const chartResponse = response.data.chart_data;

        // Set from_date and to_date from the API response
        setFromDate(response.data.from_date);
        setToDate(response.data.to_date);

        // Ensure that chartResponse properties exist before accessing them
        const labels = chartResponse.labels && chartResponse.labels.length > 1
          ? chartResponse.labels
          : chartResponse.labels ? [...chartResponse.labels, chartResponse.labels[0]] : [];
        const grossRevenue = chartResponse.gross_revenue && chartResponse.gross_revenue.length > 1
          ? chartResponse.gross_revenue
          : chartResponse.gross_revenue ? [...chartResponse.gross_revenue, chartResponse.gross_revenue[0]] : [];
        const spCommission = chartResponse.sp_commission && chartResponse.sp_commission.length > 1
          ? chartResponse.sp_commission
          : chartResponse.sp_commission ? [...chartResponse.sp_commission, chartResponse.sp_commission[0]] : [];
        const agencyCommission = chartResponse.agency_commission && chartResponse.agency_commission.length > 1
          ? chartResponse.agency_commission
          : chartResponse.agency_commission ? [...chartResponse.agency_commission, chartResponse.agency_commission[0]] : [];
        const netToCreator = chartResponse.net_to_creator && chartResponse.net_to_creator.length > 1
          ? chartResponse.net_to_creator
          : chartResponse.net_to_creator ? [...chartResponse.net_to_creator, chartResponse.net_to_creator[0]] : [];

        const newChartData = {
          labels: labels,
          datasets: [
            { label: 'Gross Revenue', data: grossRevenue, borderColor: 'blue', fill: false },
            { label: 'SP Commission', data: spCommission, borderColor: 'orange', fill: false },
            { label: 'Agency Commission', data: agencyCommission, borderColor: 'green', fill: false },
            { label: 'Net to Creator', data: netToCreator, borderColor: 'black', fill: false },
          ],
        };

        setChartData(newChartData);
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };



  const fetchRevenueData = useCallback(async (page = 1) => {
    try {
      let url = `revenue-split?page=${page}&`;

      if (startDate) {
        url += `start_date=${startDate.toISOString().split("T")[0]}&`;
      }
      if (endDate) {
        url += `end_date=${endDate.toISOString().split("T")[0]}&`;
      }

      if (searchTerm) {
        url += `search=${searchTerm}&`;
      }

      if (selectedFilters.length > 0) {
        const filterQuery = selectedFilters
          .map((filter) => `filter_type[]=${filter}`)
          .join("&");
        url += `${filterQuery}&`;
      }

      const response = await api.get(url);
      if (response.data && response.data.success) {
        setRevenueData(response.data.revenue_split);
        setTotalPages(response.data.pagination.last_page); 
        setNextPageUrl(response.data.pagination.next_page_url);
        setPrevPageUrl(response.data.pagination.prev_page_url);
      }
    } catch (error) {
      console.error('Error fetching revenue split data:', error);
    }
  }, [startDate, endDate, searchTerm, selectedFilters]);

  useEffect(() => {
    fetchRevenueData(currentPage);
    handleDropdownClick({ label: '1 Month', filter: 'month' });
  }, [fetchRevenueData, currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };


  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.4,
        borderWidth: 2,
      },
      point: {
        radius: 5,
        backgroundColor: 'white',
        borderWidth: 2,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Amount (€)',
        },
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };



  return (
    <div className="revenuesplits-page">
      {/* Header */}
      <div className="revenuesplits-header d-flex justify-content-between align-items-center p-4">
        <div className="d-flex align-items-center">
          <img src={`${process.env.PUBLIC_URL}/icons/TransactionFrame.svg`} alt="Transaction Icon" className="transaction-icon me-3" />
          <h2 className="heading-main">Revenue Splits</h2>
        </div>

        {/* Date Range Filters */}
        <div className="d-flex align-items-center">
          <div className="date-input-wrapper me-3">
            <div className="icon-with-text">
              <img src={`${process.env.PUBLIC_URL}/icons/Date.svg`} alt="Calendar Icon" className="icon" />
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="From"
                className="date-picker-input"
              />
            </div>
          </div>

          <div className="date-input-wrapper me-3">
            <div className="icon-with-text">
              <img src={`${process.env.PUBLIC_URL}/icons/Date.svg`} alt="Calendar Icon" className="icon" />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="To"
                className="date-picker-input"
              />
            </div>
          </div>

          <button className="btn custom-buttons text-white" onClick={fetchRevenueData}>GET DETAILS</button>
        </div>
      </div>


      {/* Chart */}
      <div className="chart-container">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div>
            <h3>Revenue Stats</h3>
            {fromDate && toDate && <p>({fromDate} - {toDate})</p>}
          </div>
          <div>
            {/* Custom Legend */}
            <div className="custom-legend">
              <span style={{ color: "#00F", }}><i className="blue-line"></i> Gross revenue</span>
              <span style={{ color: "#FF8E0E", }}><i className="orange-line"></i> SP Commission</span>
              <span style={{ color: "#08A822", }}><i className="green-line"></i> Agency Commission</span>
              <span style={{ color: "#404040", }}><i className="black-line"></i> Net to Creator</span>
            </div>
          </div>
          <Dropdown>
            <Dropdown.Toggle variant="light" id="custom-dropdown-basic" className="custom-toggle-button">
              {selectedTimeRange}
            </Dropdown.Toggle>
            <Dropdown.Menu className="custom-dropdown-menu">
              {timeRanges.map((range, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleDropdownClick(range)}
                  className="custom-menu-item"
                >
                  {range.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div style={{ width: '100%', height: '400px' }}>
          {chartData ? (
            <Line data={chartData} options={chartOptions} />
          ) : (
            <p>Loading chart data...</p>
          )}
        </div>
      </div>


      {/* Search and Filter */}
      <div className="search-filter-container d-flex align-items-center">
        <div className="search-barss me-3">
          <input
            type="text"
            className="search-inputs"
            placeholder="Search By Creator"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <img src={`${process.env.PUBLIC_URL}/icons/Search.svg`} alt="Search" className="search-icon" />
        </div>
        <div className="filter-btns" onClick={toggleModal}>
          <img src={`${process.env.PUBLIC_URL}/icons/Filter.svg`} alt="Filter" className="filter-icon" />
        </div>
      </div>


      {/* Revenue Splits Table */}
      <div className="revenuesplits-table-wrapper mt-4">
        <table className="revenuesplits-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Creator</th>
              <th>Paid For</th>
              <th>Amount</th>
              <th>Sneakpeach Commission</th>
              <th>Agency Commission</th>
              <th>Net To Creator</th>
            </tr>
          </thead>
          <tbody>
            {revenueData.length > 0 ? (
              revenueData.map((transaction, index) => (
                <tr key={index}>
                  <td>{transaction.formatted_date}</td>
                  <td>{transaction.creator}</td>
                  <td>{transaction.paid_for}</td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.sneakpeach_commission}</td>
                  <td>{transaction.agency_commission}</td>
                  <td>{transaction.net_to_creator}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" style={{ textAlign: 'center' }}>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
                      alt="No Content Available"
                      style={{ width: '50%' }}
                    />
                    <p>No Revenue Splits available {searchTerm}</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>


      


      {/* Mobile/Tablet Toggle View */}
      <div className="revenuesplits-mobile-view">
        {revenueData.length > 0 ? (
          revenueData.map((transaction, index) => (
            <div className="m-gigs-purchase" key={index}>
              <div className="purchase-btn" onClick={() => toggleItem(index)}>
                <div className="text"><h3>{transaction.creator}</h3></div>
                <div className="arrow">
                  <img src={`${process.env.PUBLIC_URL}/icons/Arrow.svg`} alt="Toggle" className={openItemIndex === index ? 'arrow_up' : 'arrow_down'} />
                </div>
              </div>

              {openItemIndex === index && (
                <div className="m-purchase-body">
                  <div className="m-purchase-text"><p>Date</p><h5>{transaction.formatted_date}</h5></div>
                  <div className="m-purchase-text"><p>Paid for</p><h5>{transaction.paid_for}</h5></div>
                  <div className="m-purchase-text"><p>Amount</p><h5>{transaction.amount}</h5></div>
                  <div className="m-purchase-text"><p>Sneakpeach Commission</p><h5>{transaction.sneakpeach_commission}</h5></div>
                  <div className="m-purchase-text"><p>Agency Commission</p><h5>{transaction.agency_commission}</h5></div>
                  <div className="m-purchase-text"><p>Net to Creator</p><h5>{transaction.net_to_creator}</h5></div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div>
          <img
            src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
            alt="No Content Available"
            style={{ width: '50%' }}
          />
          <p>No Revenue Splits available {searchTerm}</p>
        </div>
        )}
      </div>


      {(nextPageUrl || prevPageUrl) && (
        <div className="pagination">
          <button
            className="pagination-btn"
            disabled={!prevPageUrl || currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            &lt;
          </button>

          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`pagination-btn ${currentPage === index + 1 ? "active" : ""}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}

          <button
            className="pagination-btn"
            disabled={!nextPageUrl || currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            &gt;
          </button>
        </div>
      )}

      {/* Modal for Filters */}
      <div className={`modal-container ${isModalOpen ? 'show' : ''}`}>
        <div className="modal-content" ref={modalRef}>
          <div className="modal-header">
            <h2>FILTERS</h2>
            <button className="close-btn" onClick={toggleModal}>
              <img src={`${process.env.PUBLIC_URL}/icons/Closebtn.svg`} alt="Close Button" />
            </button>
          </div>
          <div className="modal-body">
            <h4>Paid For</h4>
            <div className="checkbox-list">
              <div className="custom-checkbox">
                <input type="checkbox" id="subscription" checked={selectedFilters.includes('subscription')} onChange={() => handleFilterChange('subscription')} />
                <label htmlFor="subscription">Subscription</label>
              </div>
              <div className="custom-checkbox">
                <input type="checkbox" id="tip" checked={selectedFilters.includes('tip')} onChange={() => handleFilterChange('tip')} />
                <label htmlFor="tip">Tips</label>
              </div>
              <div className="custom-checkbox">
                <input type="checkbox" id="unlock" checked={selectedFilters.includes('unlock')} onChange={() => handleFilterChange('unlock')} />
                <label htmlFor="unlock">Unlock</label>
              </div>
              <div className="custom-checkbox">
                <input type="checkbox" id="renewals" checked={selectedFilters.includes('renewals')} onChange={() => handleFilterChange('renewals')} />
                <label htmlFor="renewals">Renewals</label>
              </div>
              <div className="custom-checkbox">
                <input type="checkbox" id="gig" checked={selectedFilters.includes('gig')} onChange={() => handleFilterChange('gig')} />
                <label htmlFor="gig">Gigs</label>
              </div>
              <div className="custom-checkbox">
                <input type="checkbox" id="Private gig" checked={selectedFilters.includes('Private gig')} onChange={() => handleFilterChange('Private gig')} />
                <label htmlFor="Private gig">Private gig</label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="reset-btn" onClick={() => setSelectedFilters([])}>RESET</button>
            <button className="apply-btn" onClick={toggleModal}>APPLY FILTERS</button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default RevenueSplits;
