import React, { useState, useEffect, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Payouts/Payouts.css";
import { api } from "../../utility/api";

const Payouts = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [payoutsData, setPayoutsData] = useState([]);
  const [openItemIndex, setOpenItemIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);



  const toggleItem = (index) => {
    setOpenItemIndex(openItemIndex === index ? null : index);
  };


  const fetchPayouts = useCallback(async (page = 1) => {
    try {
      let url = `payouts?page=${page}&`;

      if (startDate) {
        url += `start_date=${startDate.toISOString().split("T")[0]}&`;
      }
      if (endDate) {
        url += `end_date=${endDate.toISOString().split("T")[0]}&`;
      }

      if (searchTerm) {
        url += `search=${searchTerm}&`;
      }

      const response = await api.get(url);
      const data = response.data;

      if (data.payouts && data.payouts.length > 0) {
        setPayoutsData(data.payouts);
        setTotalPages(data.pagination.last_page); 
        setNextPageUrl(data.pagination.next_page_url); 
        setPrevPageUrl(data.pagination.prev_page_url); 
      } else {
        setPayoutsData([]);
      }
    } catch (error) {
      console.error("Error fetching payouts:", error);
      setPayoutsData([]); 
    }
  }, [startDate, endDate, searchTerm]);

  useEffect(() => {
    fetchPayouts(currentPage); 
  }, [fetchPayouts, currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page); 
    }
  };

  return (
    <div className="payouts-page">
      {/* Header */}
      <div className="payouts-header d-flex justify-content-between align-items-center p-4">
        <div className="d-flex align-items-center">
          <img
            src={`${process.env.PUBLIC_URL}/icons/TransactionFrame.svg`}
            alt="Payout Icon"
            className="payout-icon me-3"
          />
          <h2 className="heading-main">Payouts</h2>
        </div>

        {/* Date Range Filters */}
        <div className="d-flex align-items-center">
          <div className="date-input-wrapper me-3">
            <div className="icon-with-text">
              <img
                src={`${process.env.PUBLIC_URL}/icons/Date.svg`}
                alt="Calendar Icon"
                className="icon"
              />
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="From"
                className="date-picker-input"
              />
            </div>
          </div>

          <div className="date-input-wrapper me-3">
            <div className="icon-with-text">
              <img
                src={`${process.env.PUBLIC_URL}/icons/Date.svg`}
                alt="Calendar Icon"
                className="icon"
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="To"
                className="date-picker-input"
              />
            </div>
          </div>

          {/* Get Details button */}
          <button className="btn custom-button text-white" onClick={fetchPayouts}>
            GET DETAILS
          </button>
        </div>
      </div>

      {/* Search and Filter */}
      <div className="search-filter-container d-flex align-items-center">
        <div className="search-bars_pyout  me-3">
          <input
            type="text"
            className="search-inputs"
            placeholder="Search By Creator"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <img
            src={`${process.env.PUBLIC_URL}/icons/Search.svg`}
            alt="Search"
            className="search-icon"
            onClick={fetchPayouts}
            style={{ cursor: "pointer" }}
          />
        </div>

      </div>

      {/* Payouts Table */}
      <div className="payouts-table-wrapper mt-4">
        <table className="payouts-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Paid For</th>
              <th>Creator</th>
              <th>Username</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Invoice</th>
            </tr>
          </thead>
          <tbody>
            {payoutsData.length > 0 ? (
              payoutsData.map((payout) => (
                <tr key={payout.id}>
                  <td>{payout.payment_date}</td>
                  <td>{payout.paid_for}</td>
                  <td>{payout.name}</td>
                  <td>{payout.username}</td>
                  <td>€{payout.amount}</td>
                  <td>{payout.status}</td>
                  <td>
                    <a href={payout.invoiceLink} download>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons/Invoice.svg`}
                        alt="Invoice"
                        className="invoice-icon"
                      />
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
                    alt="No Payouts Available"
                    style={{ width: "50%", marginTop: "20px" }}
                  />
                  <p>No Payouts Available for {searchTerm}</p>
                </td>
              </tr>
            )}
          </tbody>

        </table>
      </div>

      {/* Mobile Toggle View */}
      <div className="payouts-mobile-view">
        {payoutsData.length > 0 ? (
          payoutsData.map((payout, index) => (
            <div className="m-gigs-purchase" key={payout.id}>
              <div className="purchase-btn" onClick={() => toggleItem(index)}>
                <div className="text">
                  <h3>{payout.username}</h3>
                </div>
                <div className="arrow">
                  <img
                    src={`${process.env.PUBLIC_URL}/icons/Arrow.svg`}
                    alt="toggle"
                    className={openItemIndex === index ? "arrow_up" : "arrow_down"}
                  />
                </div>
              </div>

              {/* Toggle content */}
              {openItemIndex === index && (
                <div className="m-purchase-body">
                  <div className="m-purchase-text">
                    <p>Date</p>
                    <h5>{payout.payment_date}</h5>
                  </div>
                  <div className="m-purchase-text">
                    <p>Paid For</p>
                    <h5>{payout.paid_for}</h5>
                  </div>
                  <div className="m-purchase-text">
                    <p>Amount</p>
                    <h5>€{payout.amount}</h5>
                  </div>
                  <div className="m-purchase-text">
                    <p>Status</p>
                    <h5>{payout.status}</h5>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div><img
            src={`${process.env.PUBLIC_URL}/icons/NoContentAvail.svg`}
            alt="No Payouts Available"
            style={{ width: "50%", marginTop: "20px" }}
          />
            <p>No Payouts Available for {searchTerm}</p></div>

        )}
      </div>

      {/* Pagination */}
      {(nextPageUrl || prevPageUrl) && (
        <div className="pagination">
          <button
            className="pagination-btn"
            disabled={!prevPageUrl || currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            &lt;
          </button>

          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`pagination-btn ${currentPage === index + 1 ? "active" : ""}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}

          <button
            className="pagination-btn"
            disabled={!nextPageUrl || currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            &gt;
          </button>
        </div>
      )}

    </div>
  );
};

export default Payouts;
