import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { login } from "../../src/Redux/Auth/AuthActions";
import { useDispatch, useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const [loading, setLoading] = useState(true);
  const query = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();
  const userId = query.get('user_id');
  const role = query.get('role');

  useEffect(() => {
    if (!isAuthenticated) {
      if (userId) {
        dispatch(login(userId,role)).then(() => {
          setLoading(false);
        });
      } else {
        window.location.href = 'https://sneakpeach.com/login';
      }
    } else {
      setLoading(false);
    }
  }, [dispatch, isAuthenticated, userId ,role]);

  if (loading) {
    return <div>Loading...</div>; 
  }

  if (!isAuthenticated && !userId) {
    return null;
  }

  return children;
};

export default PrivateRoute;
