import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from "../../utility/api";

const ResetPassword = () => {
  const { code } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMessage('');
    setSuccessMessage('');

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {

      const response = await api.post('agency-reset-password', {
        mail_code: code,
        password: password,
        password_confirmation: confirmPassword,
      });

      if (response.data.success) {
        setSuccessMessage("Password reset successfully! You can now log in.");
        setErrorMessage('');
      } else {
        setErrorMessage('Failed to reset password.');
        setErrorMessage('');
      }
    } catch (error) {
      if (error.response) {
        setSuccessMessage('')
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);


        if (error.response.data.errors && error.response.data.errors.password) {
          setErrorMessage(error.response.data.errors.password[0]);
        } else {
          setErrorMessage('Failed to reset password: ' + (error.response.data.message || 'Unknown error occurred.'));
        }
      } else if (error.request) {
        setErrorMessage('No response received from server.');
      } else {
        setErrorMessage('Error setting up request.');
      }
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.textContainer}>
        <h2 style={styles.heading}>Reset Your Password</h2>
        <p style={styles.paragraph}>Create a strong and secure password to keep your account safe.</p>
      </div>

      <div style={styles.card}>
        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label htmlFor="password" style={styles.label}>Password</label>
            <input
              type="password"
              id="password"
              placeholder="Password"
              style={styles.input}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="confirmPassword" style={styles.label}>Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              placeholder="Confirm Password"
              style={styles.input}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>

          {/* Display error message */}
          {errorMessage && <p style={styles.error}>{errorMessage}</p>}

          {/* Display success message */}
          {successMessage && <p style={styles.success}>{successMessage}</p>}

          <button type="submit" style={styles.button}>UPDATE YOUR PASSWORD</button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f8f8',
    fontFamily: 'Proxima Nova sans-serif',
  },
  textContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  heading: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '10px',
  },
  paragraph: {
    fontSize: '1rem',
    color: '#666',
    marginBottom: '30px',
    lineHeight: '1.4',
  },
  card: {
    backgroundColor: '#fff',
    padding: '40px',
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '450px',
    width: '100%',
  },
  formGroup: {
    marginBottom: '20px',
    textAlign: 'left',
  },
  label: {
    fontSize: '0.9rem',
    color: '#333',
    marginBottom: '5px',
    display: 'block',
  },
  input: {
    width: '100%',
    padding: '12px 16px',
    borderRadius: '8px',
    border: '1px solid #e0e0e0',
    fontSize: '1rem',
    backgroundColor: '#f9f9f9',
    outline: 'none',
    boxSizing: 'border-box',
  },
  button: {
    width: '100%',
    padding: '12px',
    background: 'linear-gradient(90deg, #FFA626 12.26%, #FF8E0E 89.58%)',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    textTransform: 'uppercase',
    marginTop: '10px',
  },
  error: {
    color: 'red',
    marginBottom: '20px',
  },
  success: {
    color: 'green',
    marginBottom: '20px',
  },
};

export default ResetPassword;
