import axios from "axios";
import { store } from "../Redux/Auth/store"; 

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, 
  withCredentials: true, 
  
});

api.interceptors.request.use(
  (config) => {
    const state = store.getState(); 
    console.log("Data Store:" , state)
    const accessToken = state.token; 

   
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    console.log("AccessToken is there" , accessToken)
    
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    if (csrfToken) {
      config.headers['X-CSRF-TOKEN'] = csrfToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error); 
  }
);
