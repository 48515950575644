import React, { useState, useEffect } from 'react';
import { Navbar, Container, Button } from 'react-bootstrap';
import { Link, useLocation ,useNavigate } from 'react-router-dom';
import { api } from "../../utility/api";
import './Header.css'; 

const Header = ({ creatorName }) => { 
  const location = useLocation();
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] = useState(0);

  const fetchNotificationCount = async () => {
    try {
      const response = await api.get('get_notification_count');
      if (response.data.success) {
        setNotificationCount(response.data.unread_count || 0); 
      }
    } catch (error) {
      console.error('Error fetching notification count:', error);
    }
  };

  const handleNotificationClick = async () => {
    try {
      const response = await api.post('read_notification');
      if (response.data.success) {
        console.log('Notifications marked as read:', response.data);
        fetchNotificationCount(); 
      } else {
        console.error('Failed to mark notifications as read');
      }
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
    
    navigate('/notification');
  };


  
  useEffect(() => {
    fetchNotificationCount();
  }, [location.pathname]); 

  // const handleNotificationClick = () => {
  //   navigate('/notification');
  // };

  const getPageTitle = () => {
    if (location.pathname.startsWith('/creatorprofile')) {
      return creatorName || 'Loading...'; 
    }
    if (location.pathname.startsWith('/reset-password')) {
      return 'Reset Your Password';
    }
    switch (location.pathname) {
      case '/':
        return 'Dashboard';
      case '/creators':
        return 'Creators';
      case '/payouts':
        return 'Payouts';
      case '/transactions':
        return 'Transactions';
      case '/invitemanagers':
        return 'Managers';
      case '/roles':
        return 'Roles';
      case '/revenuesplits':
        return 'Managers';
      case '/profile':
        return 'Profile';
      case '/settings':
        return 'Settings';
      case '/forgot-password':
        return 'Forgot Password';
        case '/members':
          return 'Members';
          case '/notification':
            return 'Notification';
        
      default:
        return '';
    }
  };


  return (
    <Navbar className="custom-header">
      <Container fluid>
        {/* Logo (Only for mobile and tablet view) */}
        <div className="header-logo">
          <img src="/logo/logo.svg" alt="Logo" />
        </div>

        {/* Back Button */}
        <div className="back-button">
          <Link onClick={() => navigate(-1)}>
            <img  src={`${process.env.PUBLIC_URL}/icons/Hederbackspace.svg`} alt="Back" className="back-icons" />
            <span></span>
          </Link>
        </div>

        {/* {location.pathname !== '/' && (
          <div className="back-button">
            <Link onClick={() => navigate(-1)}>
              <img  src={`${process.env.PUBLIC_URL}/icons/Hederbackspace.svg`} alt="Back" className="back-icons" />
              <span></span>
            </Link>
          </div>
        )} */}


        {/* Dynamic Page Title (only visible on larger screens) */}
        <div className="page-title">
          <span>{getPageTitle()}</span>
        </div>

        {/* Right Icons */}
        <div className="header-icons">
          {/* <Button variant="link" className="icon-button">
            <img  src={`${process.env.PUBLIC_URL}/icons/Message.svg`} alt="Messages" className="header-icon" />
          </Button> */}
           <Button variant="link" className="icon-button" onClick={handleNotificationClick}>
        <div className="notification-icon-container">
          <img src={`${process.env.PUBLIC_URL}/icons/Notifaction.svg`} alt="Notifications" className="header-icon" />
          {notificationCount > 0 && (
            <div className="notification-count">{notificationCount}</div>
          )}
        </div>
      </Button>
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
